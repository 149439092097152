// src/components/Testimonials.js
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Testimonials() {
  // Sample testimonials data
  const testimonials = [
    {
      id: 1,
      name: "Kevine Iradukunda",
      content: "Working with Live Brand has been an absolute pleasure. Their professionalism and attention to detail are unmatched. I highly recommend their services!",
    },
    {
      id: 2,
      name: "Pacifique Mugisha",
      content: "I am extremely satisfied with the strategic solutions provided by Live Brand. They have helped my business grow exponentially. Thank you!",
    },
    {
      id: 3,
      name: "Jean Hubert",
      content: "I've been impressed with the level of expertise and dedication shown by the team at Live Brand. Their publishing services are top-notch!",
    },
  ];

  // Slick Carousel settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false, // Hide default navigation arrows
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false, // Hide arrows on smaller screens
        }
      }
    ]
  };

  return (
    <section id="testimonials" className="py-20 bg-gray-100">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">Testimonials</h2>
        <Slider {...settings} className="mx-auto max-w-lg">
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} className="px-4">
              <div className="bg-white rounded-lg shadow-lg p-6">
                <p className="text-lg">{testimonial.content}</p>
                <p className="text-gray-700 mt-4">- {testimonial.name}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Testimonials;

// src/components/AboutUs.js
import React from "react";

function AboutUs() {
  return (
    <section id="about" className="py-20 bg-gray-100">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-4">About Us</h2>
        <p className="mb-4">
          Welcome to Live Brand ltd, where we specialize in delivering
          strategic management, information services, and publishing solutions
          tailored to your business needs.
        </p>
        <p className="mb-4">
          Our mission is to empower businesses by providing innovative and
          reliable services.
        </p>
        <p>
          To be the leading provider of strategic solutions that drive success.
        </p>
      </div>
    </section>
  );
}

export default AboutUs;

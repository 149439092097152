// src/components/HeroSection.js
import React from 'react';
//import backgroundImage from '../path-to-your-background-image.jpg'; // Ensure the path to your image is correct

function HeroSection() {
  return (
    <section
      className="relative bg-cover bg-center text-white flex items-center justify-center"
      style={{ backgroundImage: "url(https://plus.unsplash.com/premium_photo-1682126410179-a9e65a8d558b?q=80&w=1400&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)", minHeight: 'calc(100vh - 64px)' }} // Assuming header height is 64px
    >
      <div className="absolute inset-0 bg-black opacity-50"></div> {/* Overlay */}
      <div className="relative container mx-auto px-4">
        <h1 className="text-4xl font-bold mb-4">Empowering Your Business with Strategic Solutions</h1>
        <p className="mb-8">We provide top-notch strategic management, information, and publishing services to help your business thrive.</p>
        <a href="#about" className="bg-[#EE2626] text-white px-4 py-2 rounded-full hover:bg-[#201E1F] hover:text-[#EE2626] transition-colors duration-300">Learn More</a>
      </div>
    </section>
  );
}

export default HeroSection;

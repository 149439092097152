// src/components/Services.js
import React from "react";

function Services() {
  return (
    <section id="services" className="py-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-4">Our Services</h2>
        <div className="flex flex-wrap justify-center space-x-4">
          <div className="w-full md:w-1/3 p-4 bg-white shadow-md rounded-md mb-4">
            <h3 className="text-2xl font-bold mb-2">Strategic Management</h3>
            <p>
              Our strategic management services help you navigate complex
              business environments and achieve your goals.
            </p>
          </div>
          <div className="w-full md:w-1/3 p-4 bg-white shadow-md rounded-md mb-4">
            <h3 className="text-2xl font-bold mb-2">Information Services</h3>
            <p>
              We offer comprehensive information services to keep you informed
              and ahead of the curve.
            </p>
          </div>
          <div className="w-full md:w-1/3 p-4 bg-white shadow-md rounded-md mb-4">
            <h3 className="text-2xl font-bold mb-2">Publishing Services</h3>
            <p>
              Our publishing services ensure your content reaches the right
              audience effectively.
            </p>
          </div>
          <div className="w-full md:w-1/3 p-4 bg-white shadow-md rounded-md mb-4">
            <h3 className="text-2xl font-bold mb-2">Salon Booking Services</h3>
            <p>
              We offer salon booking services through Snap Salon, a mobile
              application available on both Google Play Store and Apple Store.
              Book your appointments easily and conveniently.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;

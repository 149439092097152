// src/components/PrivacyPolicy.js
import React from "react";

function PrivacyPolicy() {
  return (
    <section className="py-20 px-4">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-4">Privacy Policy</h2>
        <p>Last updated: June 4, 2024</p>
        <p>
          Live Brand Ltd. ("we", "our", "us") is committed to protecting your
          privacy. This Privacy Policy explains how we collect, use, and
          disclose information that we obtain about visitors to our website.
        </p>
        <h3 className="text-2xl font-semibold mt-4">
          1. Information We Collect
        </h3>
        <p>
          We collect information you provide directly to us. For example, we
          collect information when you create an account, subscribe, participate
          in any interactive features of our services, fill out a form, request
          customer support, or otherwise communicate with us. The types of
          information we may collect include your name, email address, postal
          address, phone number, and other contact or identifying information
          you choose to provide.
        </p>
        <p>
          We also collect information automatically when you use our services.
          This may include your IP address, browser type, operating system,
          access times, pages viewed, links clicked, and the referring URL.
        </p>
        <h3 className="text-2xl font-semibold mt-4">2. Use of Information</h3>
        <p>
          We use the information we collect to provide, maintain, and improve
          our services, including to:
        </p>
        <ul className="list-disc list-inside">
          <li>Operate and maintain our services</li>
          <li>Provide customer support</li>
          <li>
            Communicate with you, including sending you updates, security
            alerts, and support messages
          </li>
          <li>
            Monitor and analyze trends, usage, and activities in connection with
            our services
          </li>
          <li>
            Personalize the services and provide advertisements, content, or
            features that match user profiles or interests
          </li>
          <li>
            Process transactions and send related information, including
            purchase confirmations and invoices
          </li>
        </ul>
        <h3 className="text-2xl font-semibold mt-4">
          3. Disclosure of Information
        </h3>
        <p>
          We may share information about you as follows or as otherwise
          described in this Privacy Policy:
        </p>
        <ul className="list-disc list-inside">
          <li>
            With vendors, consultants, and other service providers who need
            access to such information to carry out work on our behalf
          </li>
          <li>
            In response to a request for information if we believe disclosure is
            in accordance with, or required by, any applicable law, regulation,
            or legal process
          </li>
          <li>
            If we believe your actions are inconsistent with our user agreements
            or policies, or to protect the rights, property, and safety of Live
            Brand Ltd. or others
          </li>
          <li>
            In connection with, or during negotiations of, any merger, sale of
            company assets, financing, or acquisition of all or a portion of our
            business to another company
          </li>
        </ul>
        <h3 className="text-2xl font-semibold mt-4">4. Security</h3>
        <p>
          We take reasonable measures to help protect information about you from
          loss, theft, misuse, and unauthorized access, disclosure, alteration,
          and destruction.
        </p>
        <h3 className="text-2xl font-semibold mt-4">5. Links to Other Sites</h3>
        <p>
          Our services may contain links to other sites that are not operated by
          us. If you click a third-party link, you will be directed to that
          third party’s site. We strongly advise you to review the Privacy
          Policy of every site you visit. We have no control over and assume no
          responsibility for the content, privacy policies, or practices of any
          third-party sites or services.
        </p>
        <h3 className="text-2xl font-semibold mt-4">
          6. Changes to This Privacy Policy
        </h3>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. You are
          advised to review this Privacy Policy periodically for any changes.
          Changes to this Privacy Policy are effective when they are posted on
          this page.
        </p>
        <h3 className="text-2xl font-semibold mt-4">7. Contact Us</h3>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at shimwahono@gmail.com.
        </p>
      </div>
    </section>
  );
}

export default PrivacyPolicy;

import React from "react";
import { Link } from "react-router-dom";
import logo from "../livebrand-logo-minimized.png";

function Header() {
  return (
    <header className="bg-white py-4 shadow-md sticky top-0 z-10">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <img src={logo} alt="Company Logo" width={50} height={50} />
        </div>
        <nav>
          <ul className="flex space-x-6 text-[#201E1F]">
            <li>
              <Link
                to="/"
                className="hover:text-[#EE2626] transition-colors duration-300"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className="hover:text-[#EE2626] transition-colors duration-300"
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/services"
                className="hover:text-[#EE2626] transition-colors duration-300"
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="hover:text-[#EE2626] transition-colors duration-300"
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;

// src/components/RefundPolicy.js
import React from "react";

function RefundPolicy() {
  return (
    <section className="py-20 px-4">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-4">Refund Policy</h2>
        <p>Last updated: June 4, 2024</p>
        <h3 className="text-2xl font-semibold mt-4">
          1. Cancellation and Refunds
        </h3>
        <p>
          We offer a full refund within 30 days of purchase if you are not
          satisfied with our services. After 30 days, we will not offer a
          refund, but you may cancel your subscription at any time.
        </p>
        <h3 className="text-2xl font-semibold mt-4">2. Refund Requests</h3>
        <p>
          To request a refund, please contact our customer service team at
          shimwahono@gmail.com with your purchase details. Please include your
          order number and the reason for the refund request. We will process
          your refund within 7 business days of receiving your request.
        </p>
        <h3 className="text-2xl font-semibold mt-4">3. Refund Processing</h3>
        <p>
          Refunds will be credited to the original method of payment. If you
          have any questions about our refund policy, please contact us at
          shimwahono@gmail.com.
        </p>
        <h3 className="text-2xl font-semibold mt-4">4. Contact Us</h3>
        <p>
          If you have any questions about our Refund Policy, please contact us
          at shimwahono@gmail.com.
        </p>
      </div>
    </section>
  );
}

export default RefundPolicy;

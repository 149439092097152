import React from "react";
import HeroSection from "./HeroSection";
import AboutUs from "./AboutUs";
import Services from "./Services";
import Testimonials from "./Testimonials";
import ContactUs from "./ContactUs";

export default function () {
  return (
    <div>
      <HeroSection />
      <AboutUs />
      <Services />
      <Testimonials />
      <ContactUs />
    </div>
  );
}

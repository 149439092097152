// src/components/TermsOfService.js
import React from "react";

function TermsOfService() {
  return (
    <section className="py-20 px-4">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-4">Terms of Service</h2>
        <p>Last updated: June 4, 2024</p>
        <h3 className="text-2xl font-semibold mt-4">1. Use of Our Website</h3>
        <p>By accessing or using the website, you agree to be bound by these Terms of Service and our Privacy Policy. If you disagree with any part of the terms, then you do not have permission to access the website.</p>
        <h3 className="text-2xl font-semibold mt-4">2. Intellectual Property</h3>
        <p>The website and its original content, features, and functionality are and will remain the exclusive property of Live Brand Ltd. and its licensors.</p>
        <h3 className="text-2xl font-semibold mt-4">3. Termination</h3>
        <p>We may terminate or suspend your access to the website immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms.</p>
        <h3 className="text-2xl font-semibold mt-4">4. Limitation of Liability</h3>
        <p>In no event shall Live Brand Ltd., nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the website; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the website; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our website by any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the website; and/or (vi) the defamatory, offensive, or illegal conduct of any third party. In no event shall Live Brand Ltd. be liable for any claims, proceedings, liabilities, obligations, damages, losses, or costs in an amount exceeding the amount you paid to Live Brand Ltd. hereunder or $100.00, whichever is greater.</p>
        <h3 className="text-2xl font-semibold mt-4">5. Governing Law</h3>
        <p>These Terms shall be governed and construed in accordance with the laws of Rwanda, without regard to its conflict of law provisions.</p>
        <h3 className="text-2xl font-semibold mt-4">6. Changes to Terms</h3>
        <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
        <h3 className="text-2xl font-semibold mt-4">7. Contact Us</h3>
        <p>If you have any questions about these Terms, please contact us at shimwahono@gmail.com.</p>
      </div>
    </section>
  );
}

export default TermsOfService;
